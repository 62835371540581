import React from "react";
import Header from "../Common/Header";
import Banner from "../Elements/Banner";
import ProductList from "../Elements/ProductList";
import ProductGrid from "../Elements/ProductGrid";
import ProductBrand from "../Elements/ProductBrand";

const SwitchesAndSockets = () => {
  const wiproSwitches = [
    { name: "Champagne Gold", image: "wnw.png" },
    { name: "Silver Gray", image: "wnw1.png" },
    { name: "Matt Black", image: "wnw2.png" },
    { name: "Venia", image: "wnw.jpg" },
  ];

  const anchorSwitches = [  
    { name: "Timber Switch", image: "a-1.png" },
    { name: "Roma Classic", image: "roma.png" },
    { name: "Thea Optima Black", image: "re.png" },
    { name: "Graphite Black Penta Flat", image: "penta-flat.png" },
  ];

  const anchorSwitch = [
    // Top Row
    [
      { name: "Swift Key", image: "a-2.png", col: 3 },
      { name: "Neo Charge", image: "a-3.png", col: 3 },
      { name: "Glass Plate Tiona", image: "15.png", col: 6 },
    ],
    // Bottom Row
    [
      { name: "Penta Modular New", image: "16.png", col: 6 },
      { name: "", image: "turbodb.png", col: 6 },
    ],
  ];
  

  const wiproSwitch = [
    // Top Row
    [
      { name: "Switched Socket", image: "wbp.png", col: 3 },
      { name: "Bell Push", image: "wbp1.png", col: 3 },
      { name: "Luxe Fan Regulator", image: "luxe-fan-regulator.png", col: 6 },
    ],
    // Bottom Row
    [
      { name: "Edge Smart Panel", image: "edge-smart-panel.png", col: 6 },
      { name: "", image: "turbodb.png", col: 6 },
    ],
  ];

  return (
    <>
      <Header />
      <div className="container-fluid px-0">
        {/* Banner Section */}
        <Banner
          imageUrl={require(`../../images/switches/products/switches-banner.png`)}
          altText="Switches Banner"
          showEnquire={true}
          enquireUrl='/contact'
          bannerText={`Elegance That Speaks for Itself`}
        />

        {/* Switches & Sockets Section */}
        <section className="switches-sockets-section py-5">
          <div className="container">
            <div className="row">
              <ProductBrand
                imageUrl="switches/products/logo.jpg"
                altText="Anchor Logo"
                className="img-fluid pb-2"
                style={{ maxWidth: "200px", height: "auto" }}
              />
            </div>
            <ProductList switches={wiproSwitches} />
             <ProductGrid switches={wiproSwitch} brochureUrl={require("../../assets/catalogues/turbolight-wipronorthwest-collection.pdf")} />
            </div>
        </section>

        <section className="switches-sockets-section pt-5">
          <div className="container">
            <div className="row">
              <ProductBrand
                imageUrl="switches/products/anchorlogo.png"
                altText="Anchor Logo" 
                className="img-fluid pb-2"
              />
            </div>
            <ProductList switches={anchorSwitches} />
            <ProductGrid switches={anchorSwitch} brochureUrl={'#'} />
          </div>
        </section>
      </div>
    </>
  );
};

export default SwitchesAndSockets;
