import React from 'react';
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { IoIosArrowForward } from "react-icons/io";

const images = [
    require('./../../images/about-slider/a-14.jpg'),
];

class About1 extends React.Component {

    render() {
        const options = {
            loop: true,
            autoplay: true,
            margin: 30,
            nav: true,
            dots: true,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive: {
                0: {
                    items: 1
                },
                991: {
                    items: 1
                }
            }
        };
        return (
            <>
                <div className="section-full mobile-page-padding p-t80 p-b80" style={{ background: '#dadfe2' }}>
                    <div className="container">
                        {/* TITLE START */}
                            <div className="section-head">
                                <div className="sx-separator-outer separator-left">
                                    <div className='row d-flex flex-col'>
                                    <div className="col-lg-6 col-md-12 col-sm-12">
                                        <h3 className="pb-4" style={{ fontWeight: 200 }}>About Us</h3>
                                        <div className="about-home-lefts">
                                            <p style={{ lineHeight: '1.4em' }}>At Turbo Lights, our vision is to illuminate the nation with transformative lighting solutions that redefine living and working spaces. Integrating cutting-edge technology and sustainability, we aim to propel our country into a brighter, more energy-efficient future.</p>
                                            <p style={{ lineHeight: '1.4em' }}>As industry leaders, we are committed to sparking innovation that enriches lifestyles across the country. Over the next decade, we envision our designs in every home, office, and city, setting new standards for quality and environmental responsibility in the global market. Turbo Lights is on a mission to not only light up spaces but also to inspire and enable the next generation of lighting design.</p>
                                            <div className="text-left pt-2">
                                                <NavLink to="#" className="btn"><span>Know More</span><IoIosArrowForward className='bold' /></NavLink>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12 col-sm-12">
                                        <div className="about-home-right">
                                            <OwlCarousel className="owl-carousel about-home number-slider owl-btn-vertical-center" {...options}  style={{ boxShadow: '1px 2px 3px #444' }}>
                                                {images.map((item, index) => (
                                                    <div className="item" key={index}>
                                                        <div className="sx-img-effect">
                                                        <img src={item} alt=""  />
                                                        </div>
                                                    </div>
                                                ))}

                                            </OwlCarousel>
                                            <div className="about-home-before" style={{ top: '6%', height: '350px', right: '10px'}}>
                                                <img src={require('./../../images/homepage/sofa.jpg')} style={{height: '350px'}} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* TITLE END */}
                           
                        </div>    
                    </div>
                </div>
            </>
        );
    }
};

export default About1;