import React from "react";
import Header from "../Common/Header";
import Footer from "../Elements/FooterComponent";
import Banner from "../Elements/Banner";
import ProductList from "../Elements/ProductList";
import ProductBrand from "../Elements/ProductBrand";

const SeatingSolutions = () => {
  const seatingSolutions1 = [
    { name: "Mimeo", image: "ss1.png" },
    { name: "Acuity", image: "ss2.png" },
    { name: "Limber", image: "ss3.png" },
    { name: "Muse", image: "ss4.png" },
  ];

  const seatingSolutions2 = [
    { name: "Rock Chair", image: "ss5.png" },
    { name: "Scoop", image: "ss6.png" },
    { name: "Kym", image: "ss7.png" },
    { name: "Recharge Lounge", image: "ss8.png" },
  ];

  const wiproSeatingSolutions = [
    { name: "Adapt", image: "wp1.png" },
    { name: "Aerosit", image: "wp2.png" },
    { name: "Define", image: "wp3.png" },
    { name: "Immerse", image: "wp4.png" },
    { name: "Entity", image: "wp5.png" },
    { name: "Immerse Hobit", image: "wp6.png" },
    { name: "Trophy", image: "wp7.png" },
    { name: "Ballet", image: "wp8.png" },
  ];

  return (
    <>
      <Header />
      <div className="container-fluid px-0 pb-5">
        {/* Banner Section */}
        <Banner
          imageUrl={require(`../../images/switches/products/seating.jpg`)}
          altText="Switches Banner"
          showEnquire={true}
          enquireUrl="/contact"
          bannerText={`Crafted for Comfort, Styled for You.`}
        />

        <section className="switches-sockets-section pt-5 pb-5 mb-4">
          <div className="container">
            <div className="row ml-4">
              <ProductBrand
                imageUrl="switches/products/wpl.png"
                className="pl-10 ml-2"
                altText="Anchor Logo"
              />
            </div>
            {/* Start of HNI Seating */}
            <ProductList switches={wiproSeatingSolutions} />
            <div className="float-right">
              <a href="#">
                <img
                  src={require("../../images/switches/products/dwb.png")}
                  alt="image"
                  style={{ maxWidth: "200px" }}
                />
              </a>
            </div>
          </div>
        </section>

        <section className="switches-sockets-section pt-5 pb-5 mb-4">
          <div className="container">
            <div className="row">
              <ProductBrand
                imageUrl="switches/products/ssl1.png"
                className="pl-10"
                altText="Anchor Logo"
              />
            </div>
            {/* Start of HNI Seating */}
            <ProductList switches={seatingSolutions1} />
            <div
              style={{ display: "flex", justifyContent: "center", gap: "20px" }}
            >
              <div>
                <img
                  src={require("../../images/switches/products/ss9.png")}
                  alt="Image 1"
                  className="img-fluid"
                  style={{ maxHeight: "220px" }}
                />
                <p className="switch-title text-center">Bodie</p>
              </div>
              <div>
                <img
                  src={require("../../images/switches/products/ss10.png")}
                  alt="Image 2"
                  className="img-fluid"
                  style={{ maxHeight: "220px" }}
                />
                <p className="switch-title text-center">Ingage</p>
              </div>
            </div>
            <ProductList switches={seatingSolutions2} />
            <div className="float-right">
              <a href="#">
                <img
                  src={require("../../images/switches/products/dwb.png")}
                  alt="image"
                  style={{ maxWidth: "200px" }}
                />
              </a>
            </div>
          </div>
        </section>
        {/* End of HNI Seating */}
        {/* Start of HNI Workstations */}
        <section className="switches-sockets-section pt-5 pb-5 mb-4">
          <div className="container">
            <div className="row">
              <ProductBrand
                imageUrl="switches/products/ssl2.png"
                className="pl-10 mb-5"
                altText="Anchor Logo"
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                gap: "20px",
                padding: "0 20px",
              }}
            >
              <div>
                <img
                  src={require("../../images/switches/products/ss14.png")}
                  alt="Image 2"
                  className="img-fluid"
                  style={{ maxHeight: "350px" }}
                />
                <p className="switch-title text-center">Focus Pod</p>
              </div>
              <div>
                <img
                  src={require("../../images/switches/products/ss13.png")}
                  alt="Image 1"
                  className="img-fluid"
                  style={{ maxHeight: "350px" }}
                />
                <p className="switch-title text-center">Cuboid 2S</p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "20px",
                padding: "0 20px",
              }}
            >
              <div>
                <img
                  src={require("../../images/switches/products/ss11.png")}
                  alt="Image 1"
                  className="img-fluid"
                  style={{ maxHeight: "220px" }}
                />
                <p className="switch-title text-center">The One</p>
              </div>
              <div>
                <img
                  src={require("../../images/switches/products/ss12.png")}
                  alt="Image 2"
                  className="img-fluid"
                  style={{ maxHeight: "220px" }}
                />
                <p className="switch-title text-center">Evolve</p>
              </div>
            </div>

            {/* End of HNI Workstations */}
            <div className="float-right">
              <a href="#">
                <img
                  src={require("../../images/switches/products/dwb.png")}
                  alt="image"
                  style={{ maxWidth: "200px" }}
                />
              </a>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default SeatingSolutions;
