import React from 'react'

const FeaturedCollection = () => {
  return (
    <div class="features_room">
        <div class="container">
            <div class="row">
                <div class="col-xl-12">
                    <div class="text-center mb-100">
                        <p>Featured Products</p>
                        <h3 style={{ fontWeight: 200 }}>Discover Our Top Picks</h3>
                    </div>
                </div>
            </div>
        </div>
        <div class="rooms_here">
            <div class="single_rooms">
                <div class="room_thumb">
                  <img className="img-fluid" src={require("./../../images/homepage/living.jpg")} alt="Example Image" />
                    <div class="room_heading d-flex justify-content-between align-items-center">
                        <div class="room_heading_inner">
                            <span> For the places you unwind</span>
                            <h3 data-heading-tag="H3">Home Interiors</h3>
                        </div>
                        <a href="#" class="line-button">There's something more here</a>
                    </div>
                </div>
            </div>
            <div class="single_rooms">
                <div class="room_thumb">
                <img className="img-fluid" src={require("./../../images/homepage/corporate.jpg")} alt="Example Image" />
                    <div class="room_heading d-flex justify-content-between align-items-center">
                        <div class="room_heading_inner">
                            <span> For the places you work</span>
                            <h3 data-heading-tag="H3">Corporate Space</h3>
                        </div>
                        <a href="#" class="line-button">There's something more here</a>
                    </div>
                </div>
            </div>
            <div class="single_rooms">
                <div class="room_thumb">
                <img className="img-fluid" src={require("./../../images/homepage/cabin.jpg")} alt="Example Image" />
                    <div class="room_heading d-flex justify-content-between align-items-center">
                        <div class="room_heading_inner">
                            <span>For the ideas you imagine</span>
                            <h3 data-heading-tag="H3">Cabin</h3>
                        </div>
                        <a href="#" class="line-button">There's something more here</a>
                    </div>
                </div>
            </div>
            <div class="single_rooms">
                <div class="room_thumb">
                <img className="img-fluid" src={require("./../../images/homepage/familia.jpg")} alt="Example Image" />
                    <div class="room_heading d-flex justify-content-between align-items-center">
                        <div class="room_heading_inner">
                            <span>For the moments you celebrate</span>
                            <h3 data-heading-tag="H3">Living Room</h3>
                        </div>
                        <a href="#" class="line-button">There's something more here</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default FeaturedCollection