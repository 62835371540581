import React from "react";
import Header from "../Common/Header";
import Banner from "../Elements/Banner";
import Footer from "../Elements/FooterComponent";
import ProductBrand from "../Elements/ProductBrand";
import ProductList from "../Elements/ProductList";
import ProductGrid from "../Elements/ProductGrid";

const LightingSolutions = () => {

  const wiproSwitches = [
    { name: "Royal Radiance", image: "l1.png" },
    { name: "Caged Elegance", image: "l2.png" },
    { name: "Lemonrod Drop Pendant", image: "l3.png" },
    { name: "Goldenrod Drop Pendant", image: "l4.png" },
  ];

  const wiproSwitch = [
    // Top Row
    [
      { name: "Aura", image: "l5.png", col: 3 },
      { name: "Timber Glow", image: "l6.png", col: 3 },
      { name: "Vintage Ember", image: "l7.png", col: 6 },
    ],
    // Bottom Row
    [
      { name: "Dune Glow", image: "l8.png", col: 6 },
      { name: "", image: "turbodb.png", col: 6 },
    ],
  ];

  const anchorSwitches = [  
    { name: "InForm Range", image: "l10.png" },
    { name: "Picasso Teardrop", image: "l11.png" },
    { name: "Mute", image: "l12.png" },
    { name: "Aqua", image: "l13.png" },
    { name: "Picasso Hexadome", image: "l14.png" },
    { name: "Glitz LED (6W-18W)", image: "l15.png" },
    { name: "Radial UNO", image: "l16.png" },
    { name: "StormX Wellglass", image: "l17.png" },
    { name: "Immaculate DD LED", image: "l18.png" },
    { name: "Vertica LED", image: "l19.png" },
    { name: "TZ LED", image: "l20.png" },
    { name: "VEGA Floodlight", image: "l21.png" },
  ];

  return (
    <>
    <Header />
    <div className="container-fluid px-0 pb-5">
        {/* Banner Section */}
        <Banner
          imageUrl={require(`../../images/switches/products/l1.jpg`)}
          altText="Switches Banner"
          showEnquire={true}
          enquireUrl='/contact'
          bannerText={`Lighting That Inspires, Efficiency That Lasts.`}
        />

         {/* Switches & Sockets Section */}
         <section className="switches-sockets-section py-5">
          <div className="container">
            <div className="row">
              <ProductBrand
                imageUrl="about-slider/turbo-lights.png"
                altText="Anchor Logo"
                className="img-fluid pb-2"
                style={{ maxWidth: "200px", height: "auto" }}
              />
            </div>
            <ProductList switches={wiproSwitches} />
             <ProductGrid switches={wiproSwitch} brochureUrl={'https://drive.google.com/file/d/1ht3mYj8wzYxK2epxY8it6aW_rZRLArL0/view?usp=drive_link'} />
            </div>
        </section>

        <section className="switches-sockets-section pt-5 pb-5 mb-4">
          <div className="container">
            <div className="row">
              <ProductBrand
                imageUrl="switches/products/l9.png"
                altText="Anchor Logo" 
                className="img-fluid pb-2"
              />
            </div>
            <ProductList switches={anchorSwitches} />
            <div className="float-right">
              <a href="https://drive.google.com/file/d/1TXkvedIRbRoxyTB6md48cSn_zNEksL-3/view?usp=drive_link">
                  <img src={require("../../images/switches/products/dwb.png")} alt="image" style={{ maxWidth:'200px' }} />
              </a>
            </div>
          </div>
        </section>
      </div>
    <Footer />
    </>
  );
};

export default LightingSolutions;
