import React from "react";

const ProductList = ({ switches }) => {
  return (
    <div className="row text-center">
      {switches.map((item, index) => (
        <div key={index} className="col-md-3 col-sm-6 mb-4">
          <div className="switch-item p-4">
            <img
              src={require(`../../images/switches/products/${item.image}`)}
              alt={item.name}
              className="img-fluid mb-2"
            />
            <p className="switch-title">{item.name}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProductList;
