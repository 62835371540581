import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home1 from './Pages/Home1';


import ScrollToTop from './Common/ScrollToTop';
import SwitchesAndSockets from './Pages/SwitchesSockets';
import WiresandCables from './Pages/WiresandCables';
import LightingSolutions from './Pages/LightingSolutions';
import SeatingSolutions from './Pages/SeatingSolutions';
import Contact from './Pages/Contact';
import About from './Pages/About';
import Projects from './Pages/Projects';

class Components extends React.Component {
    render() {
        return (
            <BrowserRouter basename="/">
                <ScrollToTop />
                <div className="page-wraper">
                        <Routes>
                            <Route path='/' element={<Home1/>} />
                            <Route path='switches-sockets' element={<SwitchesAndSockets/>} />
                            <Route path='wires-cables' element={<WiresandCables/>} />
                            <Route path='lighting-solutions' element={<LightingSolutions/>} />
                            <Route path='seating-solutions' element={<SeatingSolutions/>} />
                            <Route path='/contact' element={<Contact/>} />
                            <Route path='/about' element={<About/>} />
                            <Route path='/projects' element={<Projects/>} />
                        </Routes>
                </div>
            </BrowserRouter>
        );
    };
};

export default Components;