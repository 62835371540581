
const ProductBrand = ({ imageUrl, altText, colSize = 3, maxWidth = "150px", className }) => {
    return (
      <div className={`col-md-${colSize} col-12 text-md-start`}>
        <img
          src={require(`../../images/${imageUrl}`)}
          alt={altText}
          className={`img-fluid mb-2 ${className}`}
        />
      </div>
    );
  };
  
  export default ProductBrand;
  