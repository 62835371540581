import React from "react";

const Banner = ({
  imageUrl,
  altText,
  showEnquire = false,
  bannerText = "",
  enquireUrl = "#",
  textColor = "",
}) => {
  return (
    <section className="banner-section relative">
      <div className="relative">
        <img src={imageUrl} alt={altText} className="banner-image" />

        {/* Banner Heading */}
        {bannerText && (
          <h2
            className="bannerheading"
            style={{
              position: "absolute",
              zIndex: 1,
              color: textColor
            }}
          >
            {bannerText.split(" ").slice(0, -3).join(" ")} <br />
            <span style={{ paddingLeft: "1rem" }}>
              {bannerText.split(" ").slice(-3).join(" ")}
            </span>
          </h2>
        )}

        {/* Enquire Now Button */}
        {showEnquire && (
          <a
            href={enquireUrl}
            id="enquire"
            style={{
              position: "absolute",
              zIndex: 1,
            }}
          >
            Enquire Now
          </a>
        )}
      </div>
    </section>
  );
};

export default Banner;
