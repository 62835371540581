import React from "react";
import Header from "../Common/Header";
import Banner from "../Elements/Banner";
import ProductList from "../Elements/ProductList";
import ProductBrand from "../Elements/ProductBrand";
import Footer from "../Common/Footer";

const WiresandCables = () => {
    const primaryWires = [
        { name: "Advance – EFFR Building Wire", image: "w1.png" },
        { name: "Advance – FR Building Wire", image: "w2.png" },
        { name: "Electrical Insulation Tape", image: "w3.png" },
        { name: "Multi Core Flexible Cable", image: "w4.png" },
    ];
    
    const polycabWires = [
        { name: "Polycab FR-LF 1100V AC IS 694", image: "w5.png" },
        { name: "Polycab Type MC PVC Jacketed AL Conductor UL 1569 600V", image: "w6.png" },
        { name: "Polycab Hydro Type LSXLPO MC IEEE 1580 0.6/1kV OR 2kV", image: "w7.png" },
        { name: "Polycab Cu XHHW-2 Type MC Steel Armoured PVC Jacket UL 1569 600V", image: "w8.png" },
        { name: "Polycab 6241B/6242B/6243B BS 7211 MC 300/500V AC", image: "w9.png" },
        { name: "Polycab BMS 300 MC-C4", image: "w10.png" },
        { name: "Polycab 02YY-K MC+1100V AC IS 694", image: "w11.png" },
        { name: "Polycab HFFR-01Z-K/03XZ-K SC 1100V AC IS 17048", image: "w12.png" },
    ];

  return (
    <>
      <Header />
      <div className="container-fluid px-0">
        {/* Banner Section */}
        <Banner
          imageUrl="https://d32zxht0g2dn3w.cloudfront.net/s3fs-public/2024-02/Light_duty_banner_desk.png"
          altText="Switches Banner"
          showEnquire={true}
          bannerText="Wires That Last, Cables That Connect"
          enquireUrl='/contact'
        />

        {/* Switches & Sockets Section */}
        <section className="switches-sockets-section py-5 mt-5">
          <div className="container">
            <div className="row">
              <ProductBrand
                imageUrl="switches/products/anchorlogo.png"
                altText="Anchor Logo"
                className="pl-10"
              />
            </div>
            <ProductList switches={primaryWires} />
            <div className="float-right">
              <a href="#">
                  <img src={require("../../images/switches/products/dwb.png")} alt="image" style={{ maxWidth:'200px' }} />
              </a>
            </div>       
          </div>
        </section>

        <section className="switches-sockets-section pt-5 pb-5 mb-4">
          <div className="container">
            <div className="row">
              <ProductBrand
                imageUrl="switches/products/polycab.png"
                className="pl-10"
                altText="Anchor Logo"
              />
            </div>
            <ProductList switches={polycabWires} />
            <div className="float-right">
              <a href="#">
                  <img src={require("../../images/switches/products/dwb.png")} alt="image" style={{ maxWidth:'200px' }} />
              </a>
            </div>
          </div>
        </section>
        
      </div>
      <Footer/>
    </>
  );
};

export default WiresandCables;
