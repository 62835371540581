import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Navbar, Nav, Container } from "react-bootstrap";
import DownloadBrochure from "../Elements/DownloadBrochure";
import MenuCategorySection from "../Elements/MenuCategorySection";

const categories = [
  {
    name: "Lighting Solutions",
    sections: [
      {
        title: "Decorative",
        subtitle: "Lighting",
        colSize: 3,
        links: [
          { name: "Hanging Lights", url: "/lighting-solutions" },
          { name: "Floor Lamp", url: "/lighting-solutions" },
          { name: "Wall Mounted Lights", url: "/lighting-solutions" },
        ],
      },
      {
        title: "Acoustic",
        subtitle: "Lighting",
        colSize: 3,
        links: [
          { name: "Axon", url: "/lighting-solutions" },
          { name: "Sparxon", url: "/lighting-solutions" },
          { name: "Splash Glaze", url: "/lighting-solutions" },
          { name: "Red Big Blaze", url: "/lighting-solutions" },
        ],
      },
      {
        title: "Indoor",
        subtitle: "Lightings",
        colSize: 3,
        links: [
          { name: "COB Lights", url: "/lighting-solutions" },
          { name: "Grid Lights", url: "/lighting-solutions" },
          { name: "Down Lights", url: "/lighting-solutions" },
          { name: "Linear Lights", url: "/lighting-solutions" },
        ],
      },
      {
        title: "Outdoor",
        subtitle: "Lighting",
        colSize: 3,
        links: [
          { name: "Canopy Lights", url: "/lighting-solutions" },
          { name: "Highway Fitting", url: "/lighting-solutions" },
          { name: "Garden Lights", url: "/lighting-solutions" },
          { name: "Street Lights", url: "/lighting-solutions" },
        ],
      },
    ],
    brochure: {
      mainImage: "/static/media/a-14.21922dfb9a3f02f8fa74.jpg",
      mainAlt: "Lighting Image",
      brochureImage: "dwb.png",
      brochureAlt: "Download Brochure",
      brochureURL: 'https://drive.google.com/file/d/17BJbyuEGJBD-YC0t43eZFsoTgIr4tpPT/view?usp=drive_link'
    },
  },
  {
    name: "Seating Solutions",
    sections: [
      {
        title: "Wipro Seating",
        subtitle: "Solutions",
        links: [
          { name: "Adapt", url: "/seating-solutions" },
          { name: "Alivio", url: "/seating-solutions" },
          { name: "Aerosit", url: "/seating-solutions" },
        ],
      },
      {
        title: "HNI",
        links: [
          { name: "Task Chairs", url: "/seating-solutions" },
          { name: "Panels", url: "/seating-solutions" },
          { name: "Sofas", url: "/seating-solutions" },
          { name: "Phonebooths", url: "/seating-solutions" },
        ],
      },
    ],
    brochure: {
      mainImage:
        "https://media.madeindesign.com/nuxeo/products/d/9/armchair-on-casters-rely-hw55-taupe-polished-aluminium-base_madeindesign_374560_original.jpg",
      mainAlt: "Seating Solutions",
      brochureImage: "dwb.png",
      brochureAlt: "Download Brochure",
    },
  },
  {
    name: "Wires & Cables",
    sections: [
      {
        title: "Panasonic",
        subtitle: "Building Wires",
        links: [
          { name: "Advance - FR", url: "/wires-cables" },
          { name: "Twin Core Twisted Cable", url: "/wires-cables" },
          { name: "Telephone & Switchboard Cable", url: "/wires-cables" },
          { name: "Super Guard PVC Tape", url: "/wires-cables" },
        ],
      },
      {
        title: "Polycab Wires",
        subtitle: "and Cables",
        links: [
          { name: "Residential", url: "/wires-cables" },
          { name: "Oil, Gas and Petrochemical", url: "/wires-cables" },
          { name: "Healthcare", url: "/wires-cables" },
          { name: "Metal Industry", url: "/wires-cables" },
        ],
      },
    ],
    brochure: {
      mainImage: require(`../../images/switches/products/wires.png`),
      mainAlt: "Wires & Cables",
      brochureImage: "dwb.png",
      brochureAlt: "Download Brochure",
    },
  },
  {
    name: "Switches & Sockets",
    sections: [
      {
        title: "Wipro",
        subtitle: "North West",
        links: [
          { name: "Matt Black", url: "/switches-sockets" },
          { name: "Luxe Fan Regulator", url: "/switches-sockets" },
          { name: "Switched Socket", url: "/switches-sockets" },
          { name: "Champagne Gold", url: "/switches-sockets" },
        ],
      },
      {
        title: "Anchor by",
        subtitle: "Panasonic",
        links: [
          { name: "Glass Plate Tiona", url: "/switches-sockets" },
          { name: "Regent", url: "/switches-sockets" },
          { name: "Penta Modular New", url: "/switches-sockets" },
          { name: "Royal Edge", url: "/switches-sockets" },
        ],
      },
    ],
    brochure: {
      mainImage: require(`../../images/switches/products/swi.png`),
      mainAlt: "Switches & Sockets",
      brochureImage: "dwb.png",
      brochureAlt: "Download Brochure",
    },
  },
];

const Navigation = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const location = useLocation();
  const dropdownRef = useRef(null);

  useEffect(() => {
    setSelectedCategory(null);
    setIsDropdownOpen(false);
  }, [location.pathname]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
        setSelectedCategory(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [dropdownRef]);

  const toggleDropdown = () => {
    setIsDropdownOpen(true);
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(
      (prevCategory) => (prevCategory === category ? prevCategory : category) // Keep it selected
    );
  };

  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container className="d-flex justify-content-center">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto text-black">
            <Nav.Link href="/" className="text-black px-2">
              Home
            </Nav.Link>
            <Nav.Link href="/about" className="text-black px-2">
              About
            </Nav.Link>

            {/* Products Dropdown */}
            <div className="nav-item dropdown position-static px-2">
              <a
                className="dropdown-toggle text-black"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                onClick={toggleDropdown}
              >
                Products
              </a>

              {/* Dropdown Menu */}
              <div
                className={`dropdown-menu w-100 customdropdown ${
                  isDropdownOpen ? "show" : ""
                }`}
              >
                <div className="d-flex justify-content-center py-2">
                  <div className="category-spacing w-100 vw-100 text-center">
                    {[
                      "Lighting Solutions",
                      "Seating Solutions",
                      "Wires & Cables",
                      "Switches & Sockets",
                    ].map((category) => (
                      <button
                        key={category}
                        className={`category-button category-link px-5 ${
                          selectedCategory === category ? "active" : ""
                        }`}
                        onClick={(event) =>
                          handleCategoryClick(category, event)
                        }
                      >
                        {category}
                      </button>
                    ))}
                  </div>
                </div>

                {/* Mega Menu */}
                {selectedCategory && (
                  <div className="mega-menu w-100 pb-4 pt-0">
                    <div className="container-fluid">
                      <div className="row justify-content-center">
                        {categories.map(
                          (category) =>
                            selectedCategory === category.name && (
                              <div
                                key={category.name}
                                className="bg-light w-100"
                              >
                                <div className="container">
                                  <div className="row justify-content-center align-items-center">
                                    {/* Ensures center alignment */}
                                    {category.sections.map((section, index) => (
                                      <div key={index} className={`col-md-${category.sections.length >= 4 ? 2 : 3}`}>
                                        {/* 4 sections per row */}
                                        <MenuCategorySection
                                          title={section.title}
                                          subtitle={section.subtitle}
                                          links={section.links}
                                          viewMoreLink={`/${category.name
                                            .replace(/\s+/g, "-")
                                            .toLowerCase()}`}
                                        />
                                      </div>
                                    ))}
                                     <div className="text-center mt-3 col-md-3">
                                        <DownloadBrochure {...category.brochure} />
                                     </div>
                                  </div>
                                </div>

                                {/* Brochure at the bottom */}
                               
                              </div>
                            )
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <Nav.Link href="/projects" className="text-black px-2">
              Projects
            </Nav.Link>
            <Nav.Link href="/contact" className="text-black px-2">
              Get In Touch
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;
