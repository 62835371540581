import React from "react";

const ProductGrid = ({ switches, brochureUrl }) => {
  return (
    <section className="container text-center">
      {switches.map((row, rowIndex) => (
        <div key={rowIndex} className="row justify-content-center">
          {row.map((item, index) => (
            <div key={index} className={`col-md-${item.col} col-sm-6 mb-4`}>
              <div className="switch-item">
              {item.image === "turbodb.png" ? (
                <a href={brochureUrl} target="_blank" rel="noopener noreferrer">
                <img
                  src={require(`../../images/switches/products/${item.image}`)}
                  alt={item.name}
                  className="img-fluid"
                />
              </a>) : (
                <img
                  src={require(`../../images/switches/products/${item.image}`)}
                  alt={item.name}
                  className="img-fluid"
                />
              )}
                {item.name && <p className="m-0 mt-3">{item.name}</p>}
              </div>
            </div>
          ))}
        </div>
      ))}
    </section>
  );
};

export default ProductGrid;
