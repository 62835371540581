import React, { useState } from "react";

const PortfolioGallery = ({ portfolioItems, categories }) => {
  const [selectedCategory, setSelectedCategory] = useState("All");

  const filteredItems =
    selectedCategory === "All"
      ? portfolioItems
      : portfolioItems.filter((item) => item.category === selectedCategory);

  return (
    <div className="container text-center my-5">
      {/* Filter Buttons */}
      <div className="mb-4">
        {categories.map((category, index) => (
        <button
            key={index}
            className={`mx-2 project-btn ${
            selectedCategory === category ? "active" : ""
            }`}
            onClick={() => setSelectedCategory(category)}
        >
         {category}
       </button>
        ))}
      </div>
      
      {/* Portfolio Masonry Grid */}
      <div className="masonry-grid">
        {filteredItems.map((item) => (
          <div key={item.id} className="masonry-item">
            <div className="card shadow-sm">
              <img src={require(`../../images/projects/${item.image}`)} className="card-img-top" alt={item.title} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PortfolioGallery;