import React from 'react';
import Switcher from '../Elements/Switcher';
import FooterComponent from '../Elements/FooterComponent';

class Footer extends React.Component {
    render() {

        return (
            <>
                <Switcher/>
                <FooterComponent />
            </>
        );
    };
};

export default Footer;