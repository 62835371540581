import React, { useState, useEffect } from "react";
import Navigation from "../Common/Navigation";
import { NavLink } from "react-router-dom";

const Header = () => {
  const [logo, setLogo] = useState(require("../../images/about-slider/turbo-lights.png"));

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      const stickyheader = document.querySelector(".sticky-header");

      if (stickyheader) {
        if (offset >= 100) {
          stickyheader.classList.add("is-fixed", "color-fill");
        } else {
          stickyheader.classList.remove("is-fixed", "color-fill");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    window.updateTopMostParent = (logopath) => {
      setLogo(logopath);
    };

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <header
        className="header-style-1 nav-wide mobile-sider-drawer-menu sticky-header"
        style={{
          position: "fixed",
          top: 0,
          width: "100%",
          zIndex: 9999,
          background: "white",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div className="main-bar-wraper navbar-expand-lg">
          <div
            className="main-bar header-left-gray-block bg-white container-fluid"
            style={{ boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", zIndex: 9999 }}
          >
            <div className="container d-flex align-items-center justify-content-between">
              {/* LOGO */}
              <div className="logo-header">
                <div className="logo-header-inner logo-header-one">
                  <NavLink to={"./"}>
                    <img src={logo} alt="Turbolights India" />
                  </NavLink>
                </div>
              </div>

              {/* NAV Toggle Button */}
              <button
                id="mobile-side-drawer"
                data-target=".header-nav"
                data-toggle="collapse"
                type="button"
                className="navbar-toggler collapsed"
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar icon-bar-first" />
                <span className="icon-bar icon-bar-two" />
                <span className="icon-bar icon-bar-three" />
              </button>

              {/* MAIN NAVIGATION */}
              <div className="header-nav nav-dark navbar-collapse collapse">
                <Navigation />
              </div>

              {/* SOCIAL ICONS */}
              <div className="d-flex flex-row justify-content-end align-items-center">
                <a href="https://www.instagram.com/turbolights_india?igsh=MWwxdzA1d2M4dm10ag=="><i className="fa fa-instagram fa-lg p-2 mx-1 rounded"></i></a>
                <a href="https://www.linkedin.com/company/turbo-lights---india/"><i className="fa fa-linkedin fa-lg p-2 mx-1 rounded"></i></a>
                <a href="https://api.whatsapp.com/send?phone=919322293323&text=&source=&data="><i className="fa fa-whatsapp fa-lg p-2 mx-1 rounded"></i></a>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Push content down to prevent overlap */}
      <div style={{ paddingTop: "80px" }}></div>
    </>
  );
};

export default Header;