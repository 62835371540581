import React from "react";
import { BsEnvelopePaper } from "react-icons/bs";
import { LiaPhoneVolumeSolid } from "react-icons/lia";
import { IoMapOutline } from "react-icons/io5";
import { RxCountdownTimer } from "react-icons/rx";

const ContactForm = () => {
  const categories = [
    "Hanging Lights",
    "Acoustic Lights",
    "Indoor Lights",
    "Outdoor Lights",
    "Switches & Sockets",
    "Wires, Cables & Tapes",
    "Sofas, Chairs & Tables",
  ];

  const requirements = [
    { id: "local-domestic", label: "Local / Domestic" },
    { id: "international", label: "International" },
    { id: "retail", label: "Retail" },
    { id: "wholesale", label: "Wholesale" },
  ];

  return (
    <div className="container my-5 py-5" style={{ fontFamily: "Georgia Pro" }}>
      <div className="row g-4 align-items-center justify-content-between">
        {/* Left Side - Contact Info */}
        <div className="col-md-5 rounded helpdiv">
          <h3 className="fw-bold">We're here to help</h3>
          <p>If you have any questions, please feel free to reach us out.</p>

          <div className="d-flex align-items-center py-4">
            <BsEnvelopePaper
              style={{ fontSize: "40px", marginRight: "10px", color: "#333" }}
            />
            <div style={{ fontSize: "18px" }}>
              <p className="mb-2">
                <strong>
                  <a
                    href="mailto:shripal@turbolights.com"
                    className="text-black"
                  >
                    shripal@turbolights.com
                  </a> <br/>
                  <a href="mailto:info@turbolights.com" className="text-black">
                    info@turbolights.com
                  </a>
                </strong>
              </p>
              <p className="mb-0">We reply within 24 hours</p>
            </div>
          </div>

          <div className="d-flex align-items-center py-3">
            <LiaPhoneVolumeSolid
              style={{ fontSize: "40px", marginRight: "10px", color: "#333" }}
            />
            <div style={{ fontSize: "18px" }}>
              <p className="mb-2">
                <strong>Have Any Question?</strong>
              </p>
              <p className="mb-0">
                <a href="tel:+91932298957" className="text-black">
                  +91-932298957
                </a>, &nbsp; 
                <a href="tel:+919322510122" className="text-black">
                  +91-9322510122
                </a>
              </p>
            </div>
          </div>

          <div className="d-flex align-items-center py-3">
            <IoMapOutline
              style={{ fontSize: "60px", marginRight: "10px", color: "#333" }}
            />
            <div>
              <p className="mb-2">
                <strong>Reach Us At</strong>
              </p>
              <p className="mb-0">
                <a
                  className="text-black"
                  href="https://maps.app.goo.gl/Zigt8N9sqYE1pR967"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Shop No, Krishna Niwas, 496, 8, Kalbadevi Rd, Kalbadevi,
                  Mumbai, Maharashtra 400002.
                </a>
              </p>
            </div>
          </div>

          <div className="d-flex align-items-center  py-3">
            <RxCountdownTimer
              style={{ fontSize: "40px", marginRight: "10px", color: "#333" }}
            />
            <div>
              <p className="mb-2">
                <strong>Working Hours</strong>
              </p>
              <p className="mb-0">Mon-Fri, 10:00 AM until 6:00 PM</p>
            </div>
          </div>

          <div className="d-flex align-items-center py-3">
            <LiaPhoneVolumeSolid
              style={{ fontSize: "40px", marginRight: "10px", color: "#333" }}
            />
            <div style={{ fontSize: "18px" }}>
              <p className="mb-2">
                <strong>Need Additional Help?</strong>
              </p>
              <p className="mb-0">
                <a href="tel:+919322510199" className="text-black">
                  +91-9322510199
                </a>, &nbsp;
                <a href="tel:+919322293323" className="text-black">
                  +91-9322293323
                </a>               
              </p>
            </div>
        </div>

        </div>

        

        {/* Right Side - Form */}
        <div className="col-md-6">
          <h3 className="fw-bold">Post Your Query</h3>
          <p>
            Your email address will not be published. Required fields are marked
            *
          </p>

          <form id="contactForm">
            <div className="mb-3">
              <input
                type="text"
                className="form-control custom-inputclass"
                placeholder="Enter Your Name"
                required
              />
            </div>

            <div className="row g-3 mb-3">
              <div className="col-md-6">
                <input
                  type="email"
                  className="form-control custom-inputclass"
                  placeholder="Enter Your Email"
                  required
                />
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control custom-inputclass"
                  placeholder="Enter Your Phone Number"
                />
              </div>
            </div>

            <div className="mb-3">
              <label className="form-label fw-bold">Category:</label>
              <div className="row">
                {categories.map((item, index) => (
                  <div className="col-md-6" key={index}>
                    <div className="form-check checkdiv p-1">
                      <input
                        className="form-check-input custom-checkbox"
                        type="checkbox"
                        id={`${item.toLowerCase().replace(/\s+/g, "-")} checkboxid`}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={item.toLowerCase().replace(/\s+/g, "-")}
                      >
                        {item}
                      </label>
                    </div>
                  </div>
                ))}
              </div>

              <label className="form-label mt-3">Requirement:</label>
              <div className="row">
                {requirements.map((option) => (
                  <div className="col-md-6" key={option.id}>
                    <div className="form-check checkdiv p-1">
                      <input
                        className="form-check-input custom-checkbox"
                        type="checkbox"
                        id={option.id}
                      />
                      <label className="form-check-label" htmlFor={option.id}>
                        {option.label}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="mb-3">
              <textarea
                className="form-control custom-inputclass"
                placeholder="Tell us more about your inquiry.."
                rows="4"
                required
              ></textarea>
            </div>

            <button
              type="submit"
              className="btn bg-black text-white rounded-sm px-4 py-2 shadow-sm"
              style={{ fontFamily: "Now" }}
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
