import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const clients = Array.from({ length: 25 }, (_, i) => `${i + 1}.png`);

const ClientsLogo1 = () => {
  return (      
    <div className="section-full mobile-page-padding pt-4 pb-4">
    <div className="text-center pt-4">
      <h3 style={{ fontWeight: 200 }}>Our Esteemed Clientele</h3>
        <OwlCarousel
            className="owl-theme"
            loop
            autoplay
            autoplayTimeout={800} // Reduce delay
            autoplaySpeed={1000} // Smooth transition speed
            smartSpeed={2000} // Smooth animations
            autoplayHoverPause={false}
            margin={20}
            nav={false}
            dots={false}
            slideTransition="linear"
            responsive={{
                0: { items: 2 },
                600: { items: 3 },
                1024: { items: 8 },
            }}
            >
          {clients.map((client, index) => (
            <div key={index} className="item d-flex justify-content-center">
              <img
                src={require(`../../images/client-logos/${client}`)}
                alt={`Client ${index + 1}`}
                className="img-fluid"
                style={{ maxWidth: "150px" }}
              />
            </div>
          ))}
        </OwlCarousel>
      </div>
      </div>
  );
};

export default ClientsLogo1;
