import React from "react";
import { NavLink } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";

const MenuCategorySection = ({ title, subtitle, colSize, links, viewMoreLink }) => {
  return (
    <div className={`col-md-${colSize}`}>
      <h4 className="category-title">
        <NavLink to={viewMoreLink}>
          {title} <br /> {subtitle}
        </NavLink>
      </h4>
      <div className="d-flex flex-column">
        {links.map((link, index) => (
          <NavLink key={index} to={link.url} className="category-link">
            {link.name}
          </NavLink>
        ))}
      </div>
      <NavLink to={viewMoreLink} className="view-more float-right pr-4">
        View more <IoIosArrowForward />
      </NavLink>
    </div>
  );
};

export default MenuCategorySection;
