import React from 'react'
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const dealers = Array.from({ length: 6 }, (_, i) => `${20 + i}.png`);

const Dealers = () => {
   return (      
      <div className="section-full mobile-page-padding pt-4 pb-4">
      <div className="text-center pt-4">
        <h3 style={{ fontWeight: 200 }}>Authorized Dealers</h3>
          <OwlCarousel
              className="owl-theme"
              loop
              autoplay
              autoplayTimeout={800} // Reduce delay
              autoplaySpeed={100} // Smooth transition speed
              smartSpeed={200} // Smooth animations
              autoplayHoverPause={false}
              margin={20}
              nav={false}
              dots={false}
              slideTransition="linear"
              responsive={{
                  0: { items: 2 },
                  600: { items: 3 },
                  1024: { items: 8 },
              }}
              >
            {dealers.map((client, index) => (
              <div key={index} className="item d-flex justify-content-center">
                <img
                  src={require(`../../images/dealers/${client}`)}
                  alt={`Client ${index + 1}`}
                  className="img-fluid"
                  style={{ maxWidth: "150px" }}
                />
              </div>
            ))}
          </OwlCarousel>
        </div>
        </div>
    );
}

export default Dealers