import React from "react";
import Header from "../Common/Header";
import UnderConstruction from "../Elements/UnderConstruction";
import Footer from "../Elements/FooterComponent";

const About = () => {
  return (
    <>
      <Header />
      <UnderConstruction title="About Us" />;
      <Footer/>
    </>
  );
};

export default About;
