import React from "react";

const Footer = () => {
  return (
    <footer 
    className="footer py-4 border border-secondary border-right-0 border-left-0 border-bottom-0" 
    style={{ 
      fontSize: '18px', 
      fontFamily: 'Georgia Pro', 
      backgroundColor: "#cfcfcf"
    }}
  >
      <div className="container-fluid py-4">
        <div className="row align-items-end">
          {/* Left: Logo & Slogan */}
          <div className="col-md-3 text-center text-md-start">
            <img
              src={require("../../images/about-slider/turbo-lights.png")}  // Change to your logo path
              alt="Turbo Lights Logo"
              className="img-fluid mb-2"
              style={{ maxHeight: "100px" }}
            />
            <div className="d-flex py-2 justify-content-center">
                <a href="https://www.instagram.com/turbolights_india?igsh=MWwxdzA1d2M4dm10ag=="><i className="fa fa-instagram fa-lg p-1 mx-1 rounded"></i></a>
                <a href="https://www.linkedin.com/company/turbo-lights---india/"><i className="fa fa-linkedin fa-lg p-1 mx-1 rounded"></i></a>
                <a href="https://api.whatsapp.com/send?phone=919322293323&text=&source=&data="><i className="fa fa-whatsapp fa-lg p-1 mx-1 rounded"></i></a>
            </div>
          </div>

          {/* Middle: Links Section */}
          <div className="col-md-3">
              {/* Important Links */}
                <h4>Important Links</h4>
                <ul className="list-unstyled mb-0">
                  <li><i class="fa fa-angle-double-right"></i> <a href="/"> Home</a></li>
                  <li><i class="fa fa-angle-double-right"></i> <a href="/about"> About Us</a></li>
                  <li><i class="fa fa-angle-double-right"></i> <a href="/projects"> Projects</a></li>
                  <li><i class="fa fa-angle-double-right"></i> <a href="/contact"> Contact Us</a></li>
                </ul>
          </div>

          {/* Right: Office Locations & Socials */}
          <div className="col-md-6 text-center text-md-start">
            <h4>Office Address</h4>
           <div className="row">
            <div className="col-md-6">
              <p className="mb-2"><strong>Regd. Address:</strong></p>
              <p className="mb-0">
                <a
                  className="text-black"
                  href="https://maps.app.goo.gl/Zigt8N9sqYE1pR967"
                  target="_blank"
                  rel="noopener noreferrer"
                >Shop No, Krishna Niwas, 496, <br/> Kalbadevi Rd, Mumbai,<br/> Maharashtra 400002</a></p>
            </div>
            <div className="col-md-6">
            <p className="mb-2"><strong>Corporate Office:</strong></p>
            <p className="mb-0">
            <a
                  className="text-black"
                  href="https://maps.app.goo.gl/maGtkPXaBWvEpxSr5"
                  target="_blank"
                  rel="noopener noreferrer"
                >15NE, Laxmi Industrial Estate,<br/> Andheri West, Mumbai,<br/> Maharashtra 400053</a></p>
            </div>
           </div> 
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
