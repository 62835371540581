import React from "react";
import Header from "../Common/Header";

const UnderConstruction = ({ title }) => {
  return (
    <>
      <Header />
      <div style={styles.container}>
        <h1 style={styles.heading}>{title}</h1>
        <img 
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/f4/Page_under_construction.jpg/429px-Page_under_construction.jpg" 
          alt="Under Construction" 
          style={styles.image} 
        />
      </div>
    </>
  );
};

const styles = {
  container: {
    textAlign: "center",
    padding: "50px 20px",
    minHeight: "100vh",
    backgroundColor: "#f8f9fa",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  heading: {
    fontSize: "2.5rem",
    color: "#333",
    marginBottom: "20px",
  },
  image: {
    maxWidth: "100%",
    height: "auto",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
};

export default UnderConstruction;
