import React from "react";
import Banner from "../Elements/Banner";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import ContactForm from "../Elements/ContactForm";

const Contact = () => {
  return (
    <>
      <Header />
      <Banner
        imageUrl="https://www.brandfeedbackexchange.com/assets/frontend/img/Banner-Contact-us.webp"
        altText="Switches Banner"
        showEnquire={false}
        bannerText="Bridging the Gap – Reach Out Anytime!"
      />

      <ContactForm />


      {/* Map Component */}
      <div style={{ filter: "brightness(80%)" }}>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3773.682557983821!2d72.82654297593403!3d18.945440056056988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b0d61e7ffeaf%3A0xebf1f1087fcb1b5b!2sTurbo%20Lights%20%5B%20Dealer%20Wipro%20Lighting%20-%20Garnet%20LED%5D!5e0!3m2!1sen!2sin!4v1742326630021!5m2!1sen!2sin"
        style={{ width: "100%", height: "250px", border: "none" }}
        allowFullScreen=""
        loading="lazy"
      ></iframe>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
