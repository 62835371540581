import React from "react";

const images = require.context("../../images/homepage", false, /\.png$/);

const creations = [
  { name: "Chandeliers", img: images("./10.png") },
  { name: "Sofa", img: images("./11.png") },
  { name: "Switches", img: images("./12.png") },
  { name: "Aura", img: images("./13.png") },
  { name: "Geometric Wooden Pendants", img: images("./14.png") },
  { name: "Glowstone Pendant", img: images("./15.png") },
  { name: "Goldenrod Drop Pendant", img: images("./16.png") },
  { name: "Classic Sconce", img: images("./17.png") },
  { name: "Triple Cage Cluster Pendant", img: images("./18.png") },
  { name: "Diamond Pendant", img: images("./19.png") },
]

const Creations = () => {
  return (
    <div className="section-full mobile-page-padding pt-4 pb-4"  style={{zIndex: 10}}>
    <div className="text-center pt-4">
      <h3 style={{ fontWeight: 200 }}>Our Creations</h3>
      <div className="container">
        <div className="row row-cols-2 row-cols-md-3 row-cols-lg-5 justify-content-center">
          {creations.map((item, index) => (
            <div key={index} className="d-flex flex-column align-items-center p-2">
              <img
                src={item.img}
                alt={item.name}
                className="mb-2 img-fluid"
                style={{ maxWidth: "200px" }}
              />
              <p className="small fw-semibold text-center">{item.name}</p>
            </div>
          ))}
        </div>
      </div>
      <a href="#"><p className="mt-4 fs-5 fw-medium">That's not it, there's something more!</p></a>
    </div>
    </div>
  );
};

export default Creations;