import React from "react";

const DownloadBrochure = ({ mainImage, mainAlt, brochureImage, brochureAlt, brochureURL }) => {
  return (
      <div className="d-inline-flex flex-column justify-content-between">
        <img
          src={mainImage}
          alt={mainAlt}
          style={{ height: "250px", marginBottom: "5px", borderRadius: "10px" }}
        />
        <a href={`${brochureURL}`}>
          <img
            src={require(`../../images/switches/products/${brochureImage}`)}
            alt={brochureAlt}
            style={{ height: "40px" }}
          />
        </a>
      </div>
  );
};

export default DownloadBrochure;
