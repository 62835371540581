import React from 'react'
import Header from '../Common/Header'
import Footer from '../Elements/FooterComponent'
import PortfolioGallery from '../Elements/PortfolioGallery'
import Banner from '../Elements/Banner'

const Projects = () => {

  const portfolioItems = [
    { id: 1, category: "Apollo", image: "apollo/a1.jpeg" },
    { id: 2, category: "Apollo",  image: "apollo/a2.jpeg" },
    { id: 3, category: "Apollo",  image: "apollo/a3.jpeg" },
    { id: 4, category: "Axtria Pune", image: "axtria/1.jpeg" },
    { id: 5, category: "Axtria Pune",  image: "axtria/5.jpeg" },
    { id: 6, category: "Axtria Pune",  image: "axtria/7.jpeg" },
    { id: 7, category: "Axtria Pune", image: "axtria/2.jpeg" },
    { id: 8, category: "Axtria Pune",  image: "axtria/3.jpeg" },
    { id: 9, category: "Axtria Pune",  image: "axtria/6.jpeg" },
    { id: 10, category: "Axtria Pune", image: "axtria/4.jpeg" },
    { id: 11, category: "Axtria Pune",  image: "axtria/8.jpeg" },
    { id: 12, category: "EY Mumbai",  image: "ey/1.jpeg" },
    { id: 13, category: "IB Raipur", image: "ibr/1.jpeg" },
    { id: 14, category: "IB Raipur",  image: "ibr/2.jpeg" },
    { id: 15, category: "IB Raipur",  image: "ibr/3.jpeg" },
    { id: 16, category: "Saint Gobain", image: "saint/1.jpeg" },
    { id: 17, category: "Saint Gobain",  image: "saint/2.jpeg" },
    { id: 18, category: "Saint Gobain",  image: "saint/3.jpeg" },
    { id: 19, category: "Saint Gobain", image: "saint/4.jpeg" },
    { id: 20, category: "Saint Gobain",  image: "saint/5.jpeg" },
    { id: 21, category: "Wipro Water",  image: "wiprowater/1.jpeg" },
    { id: 22, category: "Wipro Water", image: "wiprowater/5.jpeg" },
    { id: 23, category: "Wipro Water",  image: "wiprowater/3.jpeg" },
    { id: 24, category: "Wipro Water",  image: "wiprowater/4.jpeg" },
    { id: 25, category: "Wipro Water",  image: "wiprowater/2.jpeg" },
    { id: 26, category: "Piramal",  image: "piramal/1.jpeg" },
    { id: 27, category: "Piramal",  image: "piramal/2.jpeg" },
    { id: 28, category: "Piramal",  image: "piramal/3.jpeg" },
    { id: 29, category: "Piramal",  image: "piramal/4.jpeg" },
    { id: 30, category: "Piramal",  image: "piramal/12.jpeg" },
    { id: 31, category: "Piramal",  image: "piramal/6.jpeg" },
    { id: 32, category: "Piramal",  image: "piramal/7.jpeg" },
    { id: 33, category: "Piramal",  image: "piramal/8.jpeg" },
    { id: 34, category: "Piramal",  image: "piramal/9.jpeg" },
    { id: 35, category: "Piramal",  image: "piramal/10.jpeg" },
    { id: 36, category: "Piramal",  image: "piramal/11.jpeg" },
    { id: 37, category: "Piramal",  image: "piramal/5.jpeg" },
  ];
  
  const categories = ["All", "Saint Gobain", "IB Raipur", "Axtria Pune", "Apollo", "EY Mumbai", "Wipro Water", "Piramal"];
  
  return (
    <>
    <Header />
    <div className="container-fluid px-0 pb-5">
        {/* Banner Section */}
        <Banner
          imageUrl={require(`../../images/switches/products/pro.png`)}
          altText="Switches Banner"
          showEnquire={true}
          enquireUrl='/contact'
          bannerText={`Quality projects delivered with creative excellence`}
        />
      <PortfolioGallery portfolioItems={portfolioItems} categories={categories}/>
    </div>
    <Footer/>
  </>
  )
}

export default Projects