import React from "react";
import loader from "../../images/loader.gif";

const Loader = () => {
	return (
		<div className="loading-area">
			<div className="loading-box" />
			<div className="loading-pic">
				<img src={loader} alt="Loader" style={{ width: '400px' }} />
			</div>
		</div>
	);
};

export default Loader;
